import { CircularProgress } from '@mui/material';

interface Props {
  text?: string;
  color?: string;
}

function Loading(props: Props) {
  return (
    <div style={{ width: '100%', height: 400, textAlign: 'center', paddingTop: 100 }}>
      <CircularProgress color={'primary'} style={{ alignSelf: 'center' }} />
      {props.text &&
        <h3 style={{ color: props.color }}>{props.text}</h3>
      }
    </div>
  );
}

export default Loading;