import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { USER_DATA } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import '../screens/index.css'

export default function ContainerMain({ }) {

  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  interface User {
    name: string;
  }

  useEffect(() => {
    const userData = localStorage.getItem(USER_DATA);
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  interface AppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <div
            style={{
              width: "100%",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={process.env.PUBLIC_URL + "/logo512.png"} height={40} className="header-logo" />
            <Typography
              component="span"
              variant="subtitle2"
              sx={{ flexGrow: 1, marginLeft: 2, fontSize: 16, fontWeight: "bold", color: "#fff" }}
              onClick={() => navigate("/events")}
              className="header-title"
            >
              Certificados Qazando
            </Typography>
          </div>

          <Box
            component="p"
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              component="span"
              variant="subtitle2"
              sx={{ flexGrow: 1, fontSize: 16, color: "#fff" }}
              className="header-username"
            >
              {user ? user.name : "User"}
            </Typography>

            <IconButton
              color="inherit"
              onClick={logout}
              size={"small"}
              style={{ marginLeft: 2 }}
            >
              <LogoutOutlined />
            </IconButton>
          </Box>

        </Toolbar>
      </AppBar>
      <Outlet />
    </Box>
  );
}
