import Events from "../screens/Events";
import Login from "../screens/Login";
import NotFound from "../screens/NotFound";
import EventDetails from "../screens/EventDetails";
import CreateEvent from "../screens/CreateEvent";
import ContainerMain from "../components/ContainerMain";

import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { auth } from "../api/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { USER_DATA } from "../utils/constants";

const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Login />} />
      <Route element={<ContainerMain />}>
        <Route
          path="/events"
          element={
            <RequireAuth>
              <Events />
            </RequireAuth>
          }
        />
        <Route
          path="/events/:id"
          element={
            <RequireAuth>
              <EventDetails />
            </RequireAuth>
          }
        />

        <Route
          path="/events/create"
          element={
            <RequireAuth>
              <RequireUser>
                <CreateEvent />
              </RequireUser>
            </RequireAuth>
          }
        />

      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default MyRoutes;

function RequireUser({ children }: any) {

  let location = useLocation();

  const userData = localStorage.getItem(USER_DATA);

  if (userData) {
    const userDataParsed = JSON.parse(userData);

    if (userDataParsed.role != 'admin') {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children
  }
}

function RequireAuth({ children }: any) {

  const [user, loading, error] = useAuthState(auth);

  let location = useLocation();

  if (loading) {
    return null;
  }
  if (!user || error) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // var permissions = [];
  // const userData = localStorage.getItem(USER_DATA);

  // if (userData) {
  //   const userDataParsed = JSON.parse(userData);

  //   userDataParsed.permissions.forEach((element) => {
  //     permissions.push(element);
  //   });
  // }

  return children;
}
