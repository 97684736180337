import { auth, db } from './firebase'

import {
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";

const getEvents = async () => {

    const q = query(
        collection(db, `events`),
    );

    const data = [];

    const querySnapshot = await getDocs(q);

    if (querySnapshot) {
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });
    }

    return data
};

const getEventDetails = async (project_id) => {

    const q = query(
        collection(db, `events`),
        where("id", "==", project_id),
    );

    const querySnapshot = await getDocs(q);
    var data = [];

    if (querySnapshot) {
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });
    }

    return data[0]
};

const getUserCheckins = async (user_uuid, event_id) => {

    const q = query(
        collection(db, `checkins`),
        where("user_uuid", "==", user_uuid),
        where("event_id", "==", String(event_id)),
    );

    const data = [];

    const querySnapshot = await getDocs(q);

    if (querySnapshot) {
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });
    }

    return data
};

const saveCheckin = async (user_uuid, event_id, day_id) => {
    await addDoc(collection(db, "checkins"), {
        user_uuid, event_id, day_id
    });
};

const createEvent = async (event) => {
    await addDoc(collection(db, "events"), event);
};

export {
    auth,
    db,
    getEvents,
    getEventDetails,
    getUserCheckins,
    saveCheckin,
    createEvent
};
