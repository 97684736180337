import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { USER_DATA, USER_UUID } from "../utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
import ButtonLoading from "../components/ButtonLoading";
import { getUser, loginGitHub, loginGoogle } from "./../api/user";
import { toast } from "react-toastify";
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';
import './index.css'
import back from "../assets/back.png";

function Copyright({ ...props }) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://qazando.com.br/">
        Qazando
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/projects";

  const loginWithGitHub = async () => {
    setLoading(true);

    await loginGitHub()
      .then((response: any) => {
        localStorage.clear();
        loadUserData(response.uid);
      })
      .catch((e) => {
        console.log("err: ", e);
        toast.error("Erro ao realizar login");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loginWithGoogle = async () => {
    setLoading(true);

    await loginGoogle()
      .then((response: any) => {
        localStorage.clear();
        loadUserData(response.uid);
      })
      .catch((e) => {
        toast.error("Erro ao realizar login");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadUserData = (uid: String) => {
    getUser(uid)
      .then((response: any) => {
        localStorage.setItem(USER_DATA, JSON.stringify(response));
        localStorage.setItem(USER_UUID, response.uid);

        if (response.isValidated) {
          navigate(from, { replace: true });
        } else {
          navigate("/events", { replace: true });
        }
      })
      .catch((err) => {
        console.log("err ao pegar dados do user: ", err);
      });
  };

  return (
    <Box sx={styles.container}>
      <CssBaseline />

      <Box sx={styles.content} >
        <img src="/logo.png" style={styles.logo} className="logo" />

        <Box style={styles.buttonsContainer}>
          <Box style={styles.subtitleContainer}>
            <span style={styles.subtitle1}>Bem-vindo!</span> <br />
            <span style={styles.subtitle2}>Conecte-se com sua conta Google</span>
          </Box>

          <ButtonLoading
            label="Entrar com GitHub"
            loading={loading}
            onClick={loginWithGitHub}
            style={{ ...styles.button, backgroundColor: '#000000', }}
            icon={<GitHubIcon style={styles.buttonIcon} />}
          />

          <ButtonLoading
            label="Entrar com Google"
            loading={loading}
            onClick={loginWithGoogle}
            style={{ ...styles.button, backgroundColor: '#1E90FF', }}
            icon={<GoogleIcon style={styles.buttonIcon} />}
          />

          <Link sx={{ mt: 6 }}
            href="https://api.whatsapp.com/send?phone=+5548991929692&text=Ol%C3%A1,%20gostei%20da%20ferramenta%20de%20certificados.%20Como%20fa%C3%A7o%20para%20usar%20a%20plataforma%20no%20meu%20evento?"
            target="_blank"
          >Quero fazer um evento!</Link>

          <Copyright sx={{ mt: 2 }} />
        </Box>
      </Box>
    </Box>
  );
}


const styles: any = {
  container: {
    minHeight: "100vh",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${back})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  content: {
    my: 8,
    mx: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  logo: {
    width: 300,
    marginBottom: -22
  },

  subtitleContainer: {
    justifyContent: 'center',
    alignItens: 'center',
  },

  subtitle1: {
    color: '#000',
    fontSize: 15,
    fontWeight: 600,
    letterSpacing: '0.3px'
  },

  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.3px',
    color: '#9FA2B4'
  },

  buttonsContainer: {
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#fff',
    padding: 32,
    paddingLeft: 100,
    paddingRight: 100,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  button: {
    color: '#FFFFFF', width: 300
  },

  buttonIcon: {
    marginRight: 10
  }
}
