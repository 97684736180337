import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Badge, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from '../utils/constants';
import { useEffect, useState } from 'react';
import { getEvents } from '../api/events';
import { Event } from '../interfaces/Event';
import { formatDatePtBr } from '../utils/dateUtils';
import ArowIcon from '@mui/icons-material/ArrowRightAlt';
import { toast } from 'react-toastify';
import './index.css'
import moment from 'moment-timezone';
import Loading from '../components/Loading';

export default function Events() {

  const navigate = useNavigate();

  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEvents();
  }, [])

  const fetchEvents = () => {
    getEvents()
      .then((response: Event[]) => {
        setEvents(response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const goToEventDetails = (inProgress: boolean, id: String) => {
    if (inProgress) {
      navigate(`/events/${id}`);
    } else {
      toast.error('Evento ainda não iniciado ou já encerrado!')
    }
  }

  const renderEvents = (event: Event) => {

    const today = moment.tz('America/Sao_Paulo').startOf('day');
    const dateInitEvent = moment(event.dateInit).tz('America/Sao_Paulo').startOf('day');
    const dateFinalEvent = moment(event.dateFinal).tz('America/Sao_Paulo').startOf('day');

    const inProgress = (today.isAfter(dateInitEvent) && today.isBefore(dateFinalEvent))
      || (today.isSame(dateInitEvent) || today.isSame(dateFinalEvent));

    const isPast = today.isAfter(dateFinalEvent)

    const isFuture = today.isBefore(dateInitEvent)

    return (
      <ListItem
        style={{ ...styles.cardEvent }}
        className='card-event'
        onClick={() => goToEventDetails(inProgress || isPast, event.id)}>

        <Box style={styles.content}>

          <img style={styles.bannerEvent as React.CSSProperties} src={event.banner} />

          <Box sx={styles.infoContent}>
            <Box style={styles.titleContainer} className='title-container'>
              <span style={styles.eventTitle}> {event.title} </span>

              {inProgress && (
                <Box sx={styles.badgeContainer}>
                  <Badge style={{ ...styles.badge, backgroundColor: colors.status.green }}>
                    <span style={styles.badgeText}>{'Em andamento'}</span>
                  </Badge>
                  <ArowIcon style={styles.arrow} />
                </Box>
              )}

              {!inProgress && isFuture && (
                <Badge style={{ ...styles.badge, backgroundColor: colors.status.yellow }}>
                  <span style={styles.badgeText}>{'Aguardando evento'}</span>
                </Badge>
              )}

              {!inProgress && !isFuture && isPast && (
                // <Badge style={{ ...styles.badge, backgroundColor: colors.status.red }}>
                //   <span style={styles.badgeText}>{'Evento encerrado'}</span>
                // </Badge>

                <Box sx={styles.badgeContainer}>
                  <Badge style={{ ...styles.badge, backgroundColor: colors.status.red }}>
                    <span style={styles.badgeText}>{'Evento encerrado'}</span>
                  </Badge>
                  <ArowIcon style={styles.arrow} />
                </Box>
              )}
            </Box>

            <span style={styles.eventSubtitle}> {event.subtitle} </span>
            <span style={styles.eventDate}> De {formatDatePtBr(new Date(event.dateInit))} até {formatDatePtBr(new Date(event.dateFinal))} </span>
          </Box>
        </Box>
      </ListItem>
    )
  }

  return (
    <Container sx={styles.container} className='event-container'>

      <Typography sx={styles.eventsTitle}>Eventos</Typography>

      {loading && <Loading />}

      {!loading &&
        <List style={styles.eventsListContainer}>
          {events.map((event: any) => {
            return renderEvents(event)
          })}
        </List>
      }
    </Container>
  )
}

const styles: any = {
  container: {
    paddingTop: 10,
    marginLeft: 10
  },

  content: {
    display: 'flex',
    flexDirection: 'column'
  },

  eventsTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: '#1A1A1AFF'
  },

  eventsListContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  bannerEvent: {
    width: '100%',
    maxWidth: 500,
    maxHeight: 250,
    borderRadius: 16,
  },

  cardEvent: {
    marginBottom: 5,
    backgroundColor: colors.background,
    maxWidth: 500,
    padding: 24,
    borderRadius: 16,
    marginRight: 24,
    height: '100%',
    cursor: 'pointer'
  },

  badgeContainer: {
    textAlign: 'right',
    justifyContent: 'space-between',
  },

  badge: {
    padding: 12,
    backgroundColor: colors.status.yellow,
    color: '#FFFFFF',
    borderRadius: 100,
    justifyContent: 'center',
    paddingRight: 12,
    paddingLeft: 12,
    height: 32,
    display: 'flex',
    alignItems: 'center'
  },

  badgeText: {
    fontSize: 12,
    fontWeight: 500,
  },

  infoContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2,
    width: '100%',
  },

  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: 'center',
  },

  eventTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: '#1A1A1AFF'
  },

  eventSubtitle: {
    fontSize: 16,
    fontWeight: 400,
    color: '#828282',
    whiteSpace: 'pre-line',
    width: '60%',
    height: 60,
    marginTop: 24
  },

  eventDate: {
    marginTop: 20,
    fontWeight: 500,
  },

  arrow: {
    position: 'absolute', bottom: 20, right: 30, color: '#828282'
  }
}